export default {
  images: {
    'auto-diversity': 'Auto Diversity: we will select a diversity zone for you',
    'partner-logo': 'Partner logo',
  },
  connections: {
    'minimum-term-disclaimer': `Rate limit cannot be decreased below the selected speed at time of terming.`,
    'transit-configuration': `Connection Details for ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC}`,
    'enable-bgp': `Enable BGP`,
    'ix-details': `Connection Details for ${import.meta.env.VITE_EN_PRODUCTNAME_IX} Service`,
    'request-higher-speed': `Request higher speed`,
    'assigned-ipv4': `Assigned IPv4 Address`,
    'assigned-ipv6': `Assigned IPv6 Address`,
    'gateway-ipv4': `Gateway IPv4 Address`,
    'gateway-ipv6': `Gateway IPv6 Address`,
    'assigned-bgp-ipv4': `Assigned BGP IPv4 Address`,
    'assigned-bgp-ipv6': `Assigned BGP IPv6 Address`,
    'megaport-bgp-ipv4': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} BGP IPv4 Address`,
    'megaport-bgp-ipv6': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} BGP IPv6 Address`,
    'megaport-asn': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} ASN`,
    'route-set': `Advertised Route Set`,
    'allowed-prefixes': `Allowed Prefixes`,
    'peer-as-set': 'Peer AS-SET',
    'vnic-description': `vNIC description`,
    'approve-permission-denied': `You do not have permission to approve this connection.`,
    'approve-sidebar':
      'You can approve or deny this connection request by selecting the appropriate action in the sidebar.',
    'pending-company-approval': `The connection is pending your company's approval.`,
    'no-selected-source': `Select a source service.`,
    'cloud-vxc': `Cloud ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}`,
    'transit-vxc': `${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC} ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}`,
    'marketplace-vxc': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT} ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}`,
    'service-key-vxc': `Service Key ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}`,
    'select-destination-tooltip': `The destination ports are sorted by ${import.meta.env.VITE_EN_COMPANYINFO_NAME}’s monthly averaged Network Latency Times. If no latency information is available, the destination ports are sorted alphabetically.`,
    'select-ix-location-tooltip': `An ${import.meta.env.VITE_EN_PRODUCTNAME_IX} detected in the same Metro as the source port will be displayed with a 'Local' label.`,
    'local-badge': 'Local',
    'diversity-zone-tooltip': `Order ${import.meta.env.VITE_EN_PRODUCTNAME_VXCS} to different colour zones if you want device diversity.`,
    'diversity-html': 'For further details, please refer to the {link}.',
    'diversity-documentation': 'relevant diversity documentation',
    'amsix-account': 'AMS-IX Account ID',
    'awshc-account': 'AWS Hosted Connection Account ID',
    'aws-account': 'AWS Account ID',
    'default-account': 'Account ID',
    'google-account': 'Google Cloud Account ID',
    'azure-account': 'Azure Account ID',
    'nutanix-account': 'Nutanix Account ID',
    'speed-req-external-approval-b-end': `Note: This ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} is connected to another company, and speed changes may require their approval.`,
  },
  general: {
    'open-new-tab': ' - Opens in a new tab',
    'g2-review': `We would appreciate a few moments of your time to review ${import.meta.env.VITE_EN_COMPANYINFO_NAME} through the G2 review service. We will share your email address with G2. See G2’s <a href="https://www.g2.com/static/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.g2.com/static/terms" target="_blank">Terms of Use</a>.`,
    'g2-review-title': `Review ${import.meta.env.VITE_EN_COMPANYINFO_NAME}`,
    review: 'Review',
    'dont-review': `Don't Review`,
    'access-denied': 'Access Denied',
    'creation-date': 'Creation Date',
    about: `About`,
    contact: `Contact`,
    filter: `Filter`,
    'name-ascending': `Name (A-Z)`,
    'name-descending': `Name (Z-A)`,
    'creation-date-ascending': `Creation Date (Oldest first)`,
    'creation-date-descending': `Creation Date (Newest first)`,
    'location-ascending': `Location (A-Z)`,
    'location-descending': `Location (Z-A)`,
    metadata: `Metadata`,
    unavailable: `Unavailable`,
    'unsaved-changes': 'Unsaved Changes',
    'unsaved-changes-discard-confirm':
      'By navigating away, any unsaved changes will be discarded. Are you sure you want to proceed?',
    'yes-navigate-away': 'Yes, Navigate Away',
    'no-stay': 'No, Stay',
    'search-name-location': `Search by Port Name, Country, Metro, City or Address.`,
    'powered-by-megaport': `Powered by ${import.meta.env.VITE_EN_COMPANYINFO_NAME}`,
    'start-date': `Start Date`,
    'end-date': `End Date`,
    // Used to link two dates together (e.g. 01/01/2021 To 01/01/2022)
    'date-to': 'To',
    'monetary-amount': `Amount`,
    'go-to-first-page': `Go to first page`,
    'go-to-previous-page': `Go to previous page`,
    'go-to-next-page': `Go to next page`,
    'go-to-last-page': `Go to last page`,
    'trust-center': `Trust Center`,
    languages: `Language`,
    'refresh-services': `Refresh Services`,
    'review-order': `Review Order`,
    enable: 'Enable',
    'cancel-reterm': `Click Cancel if you wish to take no action and roll over at the same price at the end of the contract term.`,
    action: 'Action',
    permit: 'Permit',
    deny: 'Deny',
  },
  aws: {
    'connection-details': `Connection Details for AWS Service`,
    'bgp-key-tooltip': `Enter a BGP Auth Key / Password (must be 6-24 characters, and contain no white space). If you don't enter a key we will generate one for you.`,
    initials: `AWS`,
  },
  menu: {
    'quoting-tool': 'Quoting Tool',
    'in-app-tools': 'In-App Tools',
    'additional-tools': 'Additional Tools',
    'bug-report': 'Bug Report',
    'what-is-new': `What's New`,
    settings: 'Settings',
    'settings-user-group': 'User',
    'settings-admin-group': 'Admin',
    'user-profile': `Profile`,
    'notification-settings': `Notifications `,
    'user-security': `Security & Access`,
    'company-profile': `Company Profile`,
    'billing-markets': `Billing Markets`,
    'manage-users': `Manage Users`,
    'company-security-settings': `Security Settings`,
    entitlements: `Company Entitlements`,
    'marketplace-profile': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}`,
  },
  'billing-markets': {
    'change-registration-number-info':
      'Contact your Account Manager or Customer Success Manager to change your Company Registration Number associated with the purchasing entity.',
    'change-legal-name-info':
      'Contact your Account Manager or Customer Success Manager to change your Company Legal Name associated with the purchasing entity.',
    'view-megaport-contacts': 'View Megaport Contact Details',
    'invoices-via-accounts-receivable': `Invoices, statements, and other standard communication from Accounts Receivable (AR) will be sent to the billing contact email as registered in your Brazil account. Please contact our General Accounts Receivable (AR) at {email} for Invoice inquiries on your Brazil account.`,
  },
  tooltips: {
    'transit-bgp-config': `Enable this option to enter BGP routing information for the B-End of your ${import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC} connection.`,
    'transit-prefixes': 'List of publicly assigned IPv4 or IPv6 networks to be advertised.',
    'assigned-ipv6': `Any IPv6 address in the assigned range may be used, except the ${import.meta.env.VITE_EN_COMPANYINFO_NAME} gateway address.`,
    bfd: `Bidirectional Forwarding Detection allows for rapid fault detection on this BGP connection. When connectivity is disrupted, the BGP session is torn down without waiting for the BGP hold timer to expire. Use of BFD can improve failover time when multiple routes are available. Note: Default settings will be used for all BGP connections on this VXC, where BFD is enabled.`,
    'bfd-group': `Note: Default settings will be used for all BGP connection on this interface where BFD is enabled`,
    'bfd-tooltip': `The default BFD Settings are not editable, the default values configured when BFD is enabled are Transmit Interval: 300ms, Receive Interval: 300ms, Multiplier: 3`,
    'account-name': `The name of your account, company or organisation. You can change this later.`,
    'auto-renew-terms-create-service': `The Minimum Term selected will automatically renew at the same price for a new {selectedTerm} month term.`,
    'auto-renew-terms-create-vxc': `The Minimum Term selected will automatically renew at the same price for a new {selectedTerm} month term, at the same committed term speed.`,
    'auto-renew-terms-edit-service': `If enabled the {selectedTerm} month term will automatically renew at the same price for a new {selectedTerm} month term.`,
    'auto-renew-terms-edit-vxc': `If enabled the {selectedTerm} month term will automatically renew at the same price for a new {selectedTerm} month term, at the same committed term speed.`,
  },
  alibaba: {
    'connection-details': `Connection Details for Alibaba Service`,
  },
  amsix: {
    'connection-details': `Connection Details for AMS-${import.meta.env.VITE_EN_PRODUCTNAME_IX} Service`,
  },
  salesforce: {
    configuration: `Connection Details for Salesforce Service`,
    name: `Salesforce`,
  },
  outscale: {
    'connection-details': `Connection Details for Outscale Service`,
  },
  ibm: {
    'connection-details': `Connection Details for IBM Cloud Service`,
  },
  'service-status': {
    'start-timezone': 'Start ({thing})',
    'end-timezone': 'End ({thing})',
    list: 'List',
    calendar: 'Calendar',
    'service-status': 'Service Status',
    'event-details': 'Event Details',
  },
  'cancel-reason': {
    'proof-of-concept': 'Proof of Concept / Test',
    other: `Reason not Listed`,
    'moved-dc': `Changing Data Centers`,
  },
  marketplace: {
    'destinations-count': `1 Destination | {count} Destinations`,
    'destination-count': `Destination Count`,
    'pluralize-destinations': `Destination | Destinations`,
    'create-vxc': `Create a new ${import.meta.env.VITE_EN_PRODUCTNAME_VXC} to this destination`,
    'no-services-title': `No available services`,
    'no-services-message': `You have no deployed services available in this region. Typically this could be because of a network region mismatch or because services that would otherwise be eligible have untagged connections. Click OK to return to your services and create one.`,
    'no-deployed-services': `You have no deployed services available in this region. Click to return to your services and create one.`,
    'select-services-destination': `Select services that this destination can provide`,
    'private-destinations': `Private services will not be visible to others viewing your profile. To enable them, change the ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} visibility option for the service.`,
    'duplicate-name-warning': `This can cause confusion for people wanting to connect to your service. Are you sure you want to save?`,
    'select-source': 'Select Source',
    facebook: 'Facebook',
    x: 'X',
    linkedin: 'LinkedIn',
    'back-to-marketplace': `Back to ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'your-profile': 'Your Profile',
    connect: 'Connect',
    'no-services': `No services found`,
    'no-services-filter': `No services found with the current filter`,
    grid: `Grid`,
    list: `List`,
    'create-profile': `Create Profile`,
    'profile-settings': `Profile Settings`,
    'profile-visibility': `Profile Visibility`,
    'service-visibility': `Service Visibility`,
    'profile-visible': `Profile is visible on ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'profile-hidden': `Profile not visible on ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'company-logo': `Company Logo`,
    preview: `Preview`,
    'edit-about': 'Edit About',
    'edit-contact': 'Edit Contact',
    'edit-service': 'Edit Service',
    'edit-visibility': 'Edit Visibility',
    'logo-notice': 'New logo may take a few minutes to appear.',
    'hidden-from-marketplace': `Hidden from ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'visible-on-marketplace': `Visible on ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT}`,
    'set-up-profile': 'Set Up Profile',
    'services-warning': `Removing the {providerTypes} provider type(s) will remove all associated service types in the following services:`,
    'thing-details': '{thing} Details',
    'public-service-warning': `By selecting this option, you understand and agree that information about the {productType} (such as its {limitType} and location) will be publicly accessible. You can change the setting for this {productType} back to Private at any time.`,
    'private-service-warning-appendix': `This service will not be shown on the ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} until your profile is set to public.`,
    size: 'size',
    speed: 'speed',
    'missing-info': `Your ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} profile is missing some required information. Please complete the required fields below to continue.`,
    'go-to-profile': `Go to my Profile`,
    overview: `Overview`,
    'review-request': 'Review Connection Request | Review Connection Requests',
    'confirm-connection': 'Confirm Connection',
    'pending-approval': 'This connection is pending your approval. Please confirm the connection details.',
    'private-services-warning': `Your services are hidden on ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT}. To let visitors view and request them, make your services public.`,
    'private-services-note': `Profile Visibility controls whether your profile appears on the ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE_SHORT}, while Service Visibility determines if individual services are visible on your profile. Both must be public for a service to be seen.`,
    'publish-disclaimer': `You can publish this service on the ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}. To configure, go to your ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} profile.`,
    'publish-disclaimer-link': `You can publish this service on the ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE}. To configure, go to your {link}`,
    'service-name': `${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} Service Name`,
  },
  validations: {
    'valid-marketplace-name': `Please enter a valid ${import.meta.env.VITE_EN_PRODUCTNAME_MARKETPLACE} display name`,
    'description-too-long': `Too many words in the service description`,
    'min-max-length-no-space': `Must be {min} to {max} characters, no white spaces`,
    'exact-length': `{thing} must be exactly {length} characters long`,
  },
  services: {
    'resource-tags': 'Resource Tags',
    'add-tags': 'Add Tags',
    'manage-tags': 'Manage Tags',
    'add-new-tag': 'Add New Tag',
    'no-resource-tags': 'No Resource Tags',
    key: 'Key',
    value: 'Value',
    'resource-tags-info':
      'A tag is custom metadata that you apply to a Megaport resource. Tags can help you identify and manage your resources.',
    'no-tag-desc': 'No tags are currently available. To get started, click the "Add New Tag" button.',
    'tags-max-limit': `You can add up to 1 tag | You can add up to {count} tags`,
    'tag-exceeded-limit': 'You have exceeded the allowable limit for tags!',
    'search-tag-placeholder': 'Type to search resource tags',
    'tags-empty-table': 'No tags are currently available',
    'invalid-tag-key': 'Key must be lowercase and can only contain letters, numbers and - . _ : / \\',
    'duplicate-tag-key': 'Key must be unique',
    'invalid-tag-value': "Value can only contain letters, numbers, space and - . _ : / {'@'} \\ +",
    'tags-added-count': `1 Tag added | {count} Tags added`,
    'auto-renew-terms-disabled': 'Auto Renew is not available for the selected term.',
    'unable-to-refresh': `Unable to refresh services`,
  },
  'company-security-settings': {
    'email-mapping-attribute': `Email mapping attribute`,
  },
  authentication: {
    'temp-password-no-your': `Temporary password`,
    'account-name-instructions': `Please enter an account name and review the terms below to continue.`,
    'signup-google-instructions': `Please enter an account name and review the terms below to continue.`,
  },
  azure: {
    'express-route': `Azure ExpressRoute`,
  },
  'page-titles': {
    'approve-connection': `Approve Connection`,
    settings: 'Settings',
  },
  ports: {
    'mve-request-6wind-evaluation': `Request 6WIND evaluation account`,

    // Cross Connect String
    'cross-connect': 'Cross Connect',
    'enable-cross-connect': 'Include Cross Connect',
    'cross-connect-disclaimer': `A Cross Connect is available on 12 month minimum term at this location.`,
    'cross-connect-not-available': `Cross Connect is not available at this location. Contact your Account Manager to discuss options.`,
    'cross-connect-termed-ports': `${import.meta.env.VITE_EN_COMPANYINFO_NAME} can provision a Cross Connect at certain data center locations on your behalf for ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} ordered on term.`,
    'cross-connect-fees-vary': `Fees vary by data center location.`,
    'cross-connect-information-refer-documentation': `For more information, refer to our {link}.`,
    'includes-cross-connect': 'Including Cross Connect',
    'cross-connect-information': 'Cross Connect Information',
    'auto-renew-terms-create-lag': `This will add multiple ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} on this ${import.meta.env.VITE_EN_PRODUCTNAME_LAG_LONG} (${import.meta.env.VITE_EN_PRODUCTNAME_LAG}). Pricing displayed & Auto Renew Term set is for all the ${import.meta.env.VITE_EN_PRODUCTNAME_PORTS} being added to the ${import.meta.env.VITE_EN_PRODUCTNAME_LAG}.`,
    'packet-filter-list': 'Packet Filter List',
    'packet-filter-lists': 'Packet Filter Lists',
    'prefix-filter-list': 'Prefix Filter List',
  },
  pricebook: {
    'monthly-rack-rate': 'Monthly Rack Rate',
    'term-discount': 'Term Discount',
    'wholesale-discount': 'Wholesale Discount',
    'partner-discount': 'Partner Discount',
    'reseller-discount': 'Reseller Discount', // API: This can be deleted once the FF reseller_discount_charge is removed at an API level
    'displayed-rate-standard': `The displayed monthly rate and discounted values reflect the standard monthly price for the service.`,
    'displayed-rate-disclaimer': `Please note that the actual monthly billing amount may vary based on future changes to this service, such as but not limited to altering the contract term or adjusting the rate limit on a ${import.meta.env.VITE_EN_PRODUCTNAME_VXC}.`,
    'pricing-information-referral': `For detailed pricing information, please refer to our {link}.`,
    'pricing-documentation': 'pricing documentation',
    'see-rate-breakdown': 'See Rate Breakdown',
    'current-monthly-rate': 'Current Monthly Rate',
    'current-monthly-rate-breakdown': 'Current Monthly Rate Breakdown',
    'cross-connect-charge': 'Cross Connect Monthly Rate',
    'monthly-rate': 'Monthly Rate',
    'one-time-fees': 'One-Time Fees',
  },
  users: {
    'activity-details': 'Activity Details',
    'more-details': 'More Details',
  },
  pagination: {
    'results-per-page': 'Results per page',
    info: 'Page {select} of {totalPages}',
  },
  cancel: {
    'unable-to-cancel': `You are unable to terminate the following service/s at this time. Please review details below:`,
  },
  company: {
    'account-name': `Account Name`,
    'auto-renew-term': `Auto Renew Term`,
    'auto-renew-contract-term': 'Automatically Renew at the same price at the end of Contract Term',
    'auto-renew-error': 'Error retrieving auto renew settings',
    'minimum-term-renewal': `Minimum Term Renewal`,
    'minimum-term-renewal-description': `Set the default state for the Contract Term Renewal option when creating new services.`,
    'more-info-docs': `For more information visit {link}.`,
    'auto-renew-option': `When creating new services on term, the option to Auto Renew the Term will be {option} by default`,
  },
  partner: {
    'account-details': 'Account Details',
    'manage-global-settings': 'Manage Global Settings',
    'global-settings-dialog': 'global settings dialog',
  },
  'mcr-config': {
    'ports-not-supported-by-protocol': `Source and destination ports can only be defined for TCP and UDP protocols.`,
    'invalid-single-port': `Single port must be a number between 0 and 65535.`,
    'invalid-port-range-limit-count': `A port range be composed of two numbers separated by a hyphen.`,
    'invalid-port-range': `Each port in the range must be a number between 0 and 65535.`,
    'invalid-port-range-order': `The first port in the range must be lower than the second port.`,
    'invalid-custom-ip-protocol': `A custom IP Protocol must be between 1 and 255.`,
    'source-ip-address': `Source IP Address`,
    'destination-ip-address': `Destination IP Address`,
    'ip-protocol-all-traffic': `All Traffic`,
    'ip-protocol-tcp': `TCP (6)`,
    'ip-protocol-udp': `UDP (17)`,
    'ip-protocol-icmp': `ICMP (1)`,
    'ip-protocol-custom': `Custom`,
    protocol: `Protocol`,
    'ip-protocol-number': `IP Protocol Number`,
    'source-ports': `Source Ports`,
    'destination-ports': `Destination Ports`,
    'packet-filter-list-general-info': `
      <p>
        Create packet filter lists that can be used on interfaces within this ${import.meta.env.VITE_EN_PRODUCTNAME_MCR} to control the traffic that will be permitted or denied.
      </p>
      <ul>
        <li>When no packet filter is configured, all traffic is permitted.</li>
        <li>When a packet filter is configured, any traffic not permitted by the filter is denied.</li>
        <li>Up to 20 packet filter lists may be configured on each ${import.meta.env.VITE_EN_PRODUCTNAME_MCR}.</li>
        <li>Each list is able to contain up to 50 entries.</li>
      </ul>
    `,
    'single-port-or-range-help': `Enter a single port number (e.g., 80) or a range (e.g., 1000-2000).`,
    'packet-filter-list-save-confirm': `Do you want to save the packet filter list changes?`,
    'packet-filter-list-loaded-success': `Successfully loaded the selected packet filter list.`,
    'packet-filter-list-loaded-fail': `Failed to load the selected packet filter list.`,
    'create-new-filter-list': `Create New Filter List`,
    'max-filter-lists-reached': `You have reached the maximum number of filter lists.`,
  },
}
