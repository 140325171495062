import sdk from '@/api-sdk'
import * as globals from '@/Globals.js'
import captureSentryError from '@/utils/CaptureSentryError'

const coreState = {
  images: [],
}

const coreGetters = {
  /**
   * Filter the list of services to only include MVEs
   * @param {Object} _state The state object for the store
   * @param {Object} _getters The getters object for the store
   * @param {Object} rootState The root state object for the store
   */
  getMVEs: (_state, _getters, rootState) => {
    return rootState.Services.services.filter(service => {
      return service.productType === globals.G_PRODUCT_TYPE_MVE
    })
  },

  /**
   * Get an MVE service by its product UID from the list of MVE services
   * @param {Object} _state The state object for the store
   * @param {Object} getters The getters object for the store
   */
  getMVE: (_state, getters) => productUid => {
    return getters.getMVEs.find(service => service.productUid === productUid)
  },

  /**
   * Get a list of all available MVE locations
   * @param {Object} _state The state object for the store
   * @param {Object} _getters The getters object for the store
   * @param {Object} _rootState The root state object for the store
   * @param {Object} rootGetters The root getters object for the store
   */
  getAvailableMveLocations: (_state, _getters, _rootState, rootGetters) => {
    // Need to reset the locations list cache to address switching between accounts
    // with the Cisco FTDv FF enabled/disabled without refreshing
    sdk.instance.resetListsCache()

    return rootGetters['Services/filteredLocations'].filter(
      location =>
        ['Deployment', 'Active'].includes(location.status) && location.products.mve?.some(mve => mve.sizes.length > 0),
    )
  },

  /**
   * Gets a list of all unique MVE vendors.
   * Only vendors present in the available MVE locations are returned.
   * @param {Object} _state The state object for the store
   * @param {Object} getters The getters object for the store
   */
  getMVEVendors: (_state, getters) => {
    return getters.getAvailableMveLocations
      .map(location => location.products.mve)
      .flat()
      .map(item => item.vendor)
      .unique()
  },

  /**
   * Get a list of all MVE images for a given location and vendor
   * @param {Object} state The state object for the store
   * @param {Object} getters The getters object for the store
   * @param {number} locationId The ID of the location to get the image for
   * @param {string} vendor The vendor name to get the image for
   */
  getImagesForLocationAndVendor: (state, getters) => (locationId, vendor) => {
    const location = getters.getAvailableMveLocations.find(l => l.id === locationId)

    return location.products.mve
      .filter(mve => mve.vendor === vendor)
      .map(mve => {
        // Find the product code for the image
        const { productCode } = state.images.find(image => image.id === mve.id)

        // Return the MVE image with the product code attached
        return { ...mve, productCode }
      })
  },

  /**
   * Get an MVE image for a given location and image ID
   * @param {Object} _state The state object for the store
   * @param {Object} getters The getters object for the store
   * @param {number} locationId The ID of the location to get the image for
   * @param {number} imageId The ID of the image to get the image for
   */
  getImageForLocationAndImageId: (_state, getters) => (locationId, imageId) => {
    const location = getters.getAvailableMveLocations.find(l => l.id === locationId)
    return location.products.mve.find(mve => mve.id === imageId)
  },
}

const actions = {
  /**
   * Fetch a list of all vendors from the API
   * @param {Object} context The context object for the store
   */
  async fetchImages(context) {
    try {
      const { mveImages } = await sdk.instance.product().getMveImages()
      context.commit('setMveImages', mveImages)
    } catch (error) {
      captureSentryError(error)
    }
  },

  /**
   * Saves a design for an MVE and adds it to the list of services
   * @param {Object} context The context object for the store
   * @param {Object} mveData The MVE data to be saved
   */
  saveDesign({ dispatch, rootState, rootGetters }, mveData) {
    let mve = {
      associatedIxs: [],
      associatedVxcs: [],
      buyoutPort: false,
      companyUid: rootState.Company.data.companyUid,
      dealUid: mveData.dealUid === 'None' ? null : mveData.dealUid || undefined,
      costCentre: mveData.costCentre,
      term: mveData.term,
      createDate: Date.now(),
      locationId: mveData.locationId,
      productName: mveData.productName,
      productType: globals.G_PRODUCT_TYPE_MVE,
      productUid: mveData.productUid,
      provisioningStatus: globals.G_PROVISIONING_DESIGN,
      vendorConfig: mveData.vendorConfig,
      config: mveData.config,
      virtual: true,
      vnics: mveData.vnics,
      resourceTags: mveData.resourceTags,
    }

    if (rootGetters['Auth/hasFeatureFlag']('auto_renew_term')) {
      mve.autoRenewTerm = mveData.autoRenewTerm
    }

    // If there is no valid diversity zone delete the field from the payload
    if (![globals.G_DIVERSITY_ZONE_RED, globals.G_DIVERSITY_ZONE_BLUE].includes(mveData.config.diversityZone)) {
      delete mveData.config.diversityZone
    }

    if (rootGetters['Services/portUidDictionary'][mve.productUid]) {
      dispatch('Services/updateExistingService', mve, { root: true })
    } else {
      dispatch('Services/addService', mve, { root: true })
    }
  },

  /**
   * Save a configured MVE
   * @param {Object} context The context object for the store
   * @param {Object} payload The payload containing the MVE data and callback function
   * @param {Object} payload.mveData The MVE data to be saved
   * @param {Function} payload.callback The callback function to be executed
   */
  async saveConfigured(context, { mveData }) {
    const existingMve = context.getters.getMVE(mveData.productUid)
    const service = { ...existingMve, ...mveData }

    // Remove the auto renew field if the feature flag is disabled
    if (!context.rootGetters['Auth/hasFeatureFlag']('auto_renew_term')) {
      delete service.autoRenewTerm
    }

    await context.dispatch('Services/editService', { service }, { root: true })
  },
}

const mutations = {
  /**
   * Set the MVE images in the store's state
   * @param {Object} state The state object for the store
   * @param {Array} images The list of MVE images
   */
  setMveImages(state, images) {
    state.images = images
  },
  /**
   * Clears the MVE images from the store's state
   * @param {Object} state The state object for the store
   */
  logout(state) {
    state.images = []
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
