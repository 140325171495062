import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import { getCompanyBaseSettingsSchema, getCompanySettingsSchema, partnerBrandingSchema } from './schemas/company'
import type { UpdateCompanySettingsPayload } from './types/company'

/**
 * Company operations
 */
export default class CompanyResource {
  #api: MegaportAPI
  #companyUid: string

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   * @param companyUid
   */
  constructor(api: MegaportAPI, companyUid = '') {
    this.#api = api
    this.#companyUid = companyUid
  }

  /**
   * Moves you out of the public company into your own company.
   *
   * Initially when a user is registered, they are assigned to the generic public company.
   *
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async upgrade(data: any, settings?: APISettings) {
    const response = await this.#api.jpost('/v2/social/company', settings, { data })
    return response.body.data || response.body
  }

  /**
   * Update the company information. The object can contain any of the fields
   * as read from the get (then) operation below.
   *
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async update(data: any, settings?: APISettings) {
    const response = await this.#api.put('/v2/company', settings, { data })
    return response.body
  }

  /**
   * Get the information about the company.
   *
   * @param callContext
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async get(callContext = false, settings?: APISettings) {
    const url = callContext ? '/v2/company' : `/v2/company/${this.#companyUid}`
    const response = await this.#api.get(url, settings)
    return response.body.data || response.body
  }

  /**
   * Get the information about the managed company.
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getManagedCompany(settings?: APISettings) {
    const response = await this.#api.get('/v2/managedCompany', settings)
    return response.body.data || response.body
  }
  /**
   * Get partner branding details for the company.
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getPartnerBrandingProfile(settings?: APISettings) {
    const response = await this.#api.get('/v2/partner-branding/profile', settings, { schema: partnerBrandingSchema })
    return response.body.data || response.body
  }

  /**
   * Get the consolidated settings details for the company.
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns List of consolidated settings for the company
   */
  async getCompanySettings(settings?: APISettings) {
    const response = await this.#api.get(`/v3/company/${this.#companyUid}/settings`, settings, {
      schema: getCompanySettingsSchema,
    })
    return response.body.data
  }

  /**
   * Get the company override and child default settings details for the company.
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns List of company overrides and child default settings for the company
   */
  async getCompanyBaseSettings(settings?: APISettings) {
    const response = await this.#api.get(`/v3/company/base/${this.#companyUid}/settings`, settings, {
      schema: getCompanyBaseSettingsSchema,
    })
    return response.body.data
  }

  /**
   * Update settings details for the company.
   *
   * @param data The new settings to apply to the company
   * @param settings Additional settings to adjust the generated API request
   * @returns List of settings for the company
   */
  async postCompanySettings(data: UpdateCompanySettingsPayload, settings?: APISettings) {
    await this.#api.put(`/v3/company/${this.#companyUid}/settings`, settings, { data })
  }
}
